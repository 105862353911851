<template>
  <div  class="wrapper">
    <!-- <div class="text-center mt-3 mb-3"> -->
      <b-card class="wrapper">
        <b-container>
          <p class="TextBlack32 mt-4 text-center">
            {{ $t("imtiyoz") }}
          </p>
          <b-row>
            <b-col
              sm="12"
              md="12"
              v-for="(item, index) in PrivilegeList"
              :key="index"
            >
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    v-b-toggle="'accordion-' + item.id"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ item.name }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="
                        'accordion-' + item.id ? 'chevron-down' : 'chevron-up'
                      "
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :id="'accordion-' + item.id"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text >
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ item.description }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <!-- <b-row class="mt-4 pt-4">
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header class="p-1 famouscard1" role="tab">
                  <b-button block @click="ischange = !ischange" variant="white"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("Davlat_mukofoti_sovrindorlari") }}
                    </p>
                    <b-icon
                      class="IconStyle "
                      :icon="ischange ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange"
                  id="accordion-1"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("DMS_info") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange2 = !ischange2"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto; position:absolute"
                    >
                      {{ $t("Mard_oglonlari_davlat_mukofoti_sovrindorlari") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange2 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange2"
                  id="accordion-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("Glon_info") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange3 = !ischange3"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto; position:absolute"
                    >
                      {{ $t("Nixol_mukofoti_sovrindorlari") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange3 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange3"
                  id="accordion-3"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("NMS_info") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange4 = !ischange4"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto; position:absolute"
                    >
                      {{ $t("Olis_COL_TUMANLARI_YOSHLARI") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange4 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange4"
                  id="accordion-4"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("OChTY_ofis") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange5 = !ischange5"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("Yosh_tasviriy_sanat_ustalar") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange5 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange5"
                  id="accordion-5"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("YTSU_info") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange6 = !ischange6"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto; position:absolute"
                    >
                      {{ $t("Yosh_fiologlar") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange6 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange6"
                  id="accordion-6"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("YF_info") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange7 = !ischange7"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("Yoshlar_ittifoqi_faollari") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange7 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange7"
                  id="accordion-7"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("YIF_info") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange8 = !ischange8"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{
                        $t("Harbiy_soha_kadrlarining_farzandlariga_imtiyozlari")
                      }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange8 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange8"
                  id="accordion-8"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("HSKFI_info") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange9 = !ischange9"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{
                        $t("Ichki_ishlar_organlari_xodimlarining_farzandlari")
                      }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange9 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange9"
                  id="accordion-9"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("IIOXF_info") }}
                      </p>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("IIOXF_info2") }}
                      </p>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("IIOXF_info3") }}
                      </p>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("IIOXF_info4") }}
                      </p>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("IIOXF_info5") }}
                      </p>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("IIOXF_info6") }}
                      </p>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("IIOXF_info7") }}
                      </p>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("IIOXF_info8") }}
                      </p>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("IIOXF_info9") }}
                      </p>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("IIOXF_info10") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange10 = !ischange10"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("Temurbeklar_maktabi_bituruvchilari") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange10 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange10"
                  id="accordion-10"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("TMB_info") }}
                      </p>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("TMB_info2") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange11 = !ischange11"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("Imkoniyati_cheklangan_yoshlar") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange11 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange11"
                  id="accordion-11"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("ICHY_info") }}
                      </p>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("ICHY_info2") }}
                      </p>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("ICHY_info3") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange12 = !ischange12"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("Mehribonlik_uyi_tarbiyalanuvchilari") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange12 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange12"
                  id="accordion-12"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("MUT_info") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange13 = !ischange13"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("Tarbiyachi_korik_tanlov_goliblari") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange13 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange13"
                  id="accordion-13"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("TKTG_info") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange14 = !ischange14"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("Xalqaro_olimpiadalar_goliblari") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange14 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange14"
                  id="accordion-14"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("XOF_info") }}
                      </p>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("XOF_info2") }}
                      </p>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("XOF_info3") }}
                      </p>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("XOF_info4") }}
                      </p>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("XOF_info5") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange15 = !ischange15"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("Ijtimoiy_himoyaga_muhtoj_xotin_qizlar") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange15 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange15"
                  id="accordion-15"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("IHMXQ_info") }}
                      </p>
                   
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange16 = !ischange16"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("Chet_tilidan_maksimal_bal") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange16 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange16"
                  id="accordion-16"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("ChTMB_info") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange17 = !ischange17"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                    >
                      {{ $t("Soxlik_maktab_bitiruvchilari_uchun") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange17 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon
                  ></b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange17"
                  id="accordion-17"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="display:block; margin-top:auto; margin-bottom:auto;text-align:left"
                      >
                        {{ $t("SMBU500G_info") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange18 = !ischange18"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto; position:absolute"
                    >
                      {{ $t("pupil_18") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange18 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange18"
                  id="accordion-18"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("pupil_18_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange21 = !ischange21"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto; position:absolute"
                    >
                      {{ $t("pupil_21") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange21 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange21"
                  id="accordion-21"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("pupil_21_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange22 = !ischange22"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto; position:absolute"
                    >
                      {{ $t("pupil_22") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange22 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange22"
                  id="accordion-22"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("pupil_22_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange26 = !ischange26"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto; position:absolute"
                    >
                      {{ $t("pupil_26") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange26 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange26"
                  id="accordion-26"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("pupil_26_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card no-body class="mb-1 famouscard1">
                <b-card-header
                  header-tag="header"
                  class="p-1 famouscard1"
                  role="tab"
                >
                  <b-button
                    block
                    @click="ischange28 = !ischange28"
                    variant="white"
                    class="text"
                    ><p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto; position:absolute"
                    >
                      {{ $t("pupil_28") }}
                    </p>
                    <b-icon
                      class="IconStyle"
                      :icon="ischange28 ? 'chevron-up' : 'chevron-down'"
                    ></b-icon>
                  </b-button>
                </b-card-header>
                <b-collapse
                  :visible="ischange28"
                  id="accordion-28"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p
                        class="school15"
                        style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                      >
                        {{ $t("pupil_28_1") }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </b-col>
          </b-row> -->
        </b-container>
      </b-card>
    <!-- </div> -->
  </div>
</template>
<script>
import PrivilegeService from "@/services/Privilege.service";
export default {
  data() {
    return {
      ischange: false,
      ischange2: false,
      ischange3: false,
      ischange4: false,
      ischange5: false,
      ischange6: false,
      ischange7: false,
      ischange8: false,
      ischange9: false,
      ischange10: false,
      ischange11: false,
      ischange12: false,
      ischange13: false,
      ischange14: false,
      ischange15: false,
      ischange16: false,
      ischange17: false,
      ischange18: false,
      ischange19: false,
      ischange20: false,
      ischange21: false,
      ischange22: false,
      ischange23: false,
      ischange24: false,
      ischange25: false,
      ischange26: false,
      ischange27: false,
      ischange28: false,
      ischange29: false,
      ischange30: false,
      ischange31: false,
      ischange32: false,
      ischange33: false,
      ischange34: false,
      ischange35: false,
      ischange36: false,
      ischange37: false,
      PrivilegeList: [],
      filter: {
        Search: "",
        SortColumn: "",
        OrderType: "asc",
        PageNumber: 1,
        PageLimit: 1000,
      },
    };
  },
  created() {
    this.filter.lang = localStorage.getItem("lang") || "uz_cyrl";
    this.Refresh();
  },
  methods: {
    Refresh() {
      PrivilegeService.GetList(
        this.filter.Search,
        this.filter.SortColumn,
        this.filter.OrderType,
        this.filter.PageNumber,
        this.filter.PageLimit,
        this.filter.lang
      ).then((res) => {
        this.PrivilegeList = res.data.rows;
      });
    },
  },
};
</script>
<style></style>
